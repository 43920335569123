export default class Events {
  channelOptions: IChannelOptions | undefined
  isChannelPaused = false

  private readonly listenersList: any = []
  private eventsQueue: any = []
  private doneQueue: any = []
  private isRunning = false
  private triggerExecOnEmptyQueue: (() => void) | undefined
  private triggerExecOnEmptyQueueOnce?: () => void

  addEventListener(eventName: string, callback: (...args: any) => void, context?: any, once?: any) {
    this.listenersList[eventName] = this.listenersList[eventName] || []
    this.listenersList[eventName].push({ callback, context, once })
  }

  removeEventListener(eventName: string, callback: (...args: any) => void, context: any) {
    const listeners = this.listenersList[eventName]

    // if no listeners subscribed to this event stop execution
    if (!listeners) return

    // remove all listeners with the same signature
    for (let i = 0; i < listeners.length; i++) {
      if (listeners[i].callback === callback && listeners[i].context === context) {
        listeners.splice(i, 1)
      }
    }
  }

  trigger(eventName: string, args?: any[]) {
    const listeners = this.listenersList[eventName]

    // if no listeners subscribed to this event stop execution
    if (!listeners && !this.isChannelPaused) return

    // push new event to the queue
    this.eventsQueue = this.eventsQueue || []
    this.eventsQueue.push({ eventName, args })

    this.proceed()
  }

  execOnEmptyQueue(cb: any) {
    this.triggerExecOnEmptyQueue = cb
  }

  execOnEmptyQueueOnce(cb: any) {
    this.triggerExecOnEmptyQueueOnce = cb
  }

  proceed() {
    // do not proceed if queue is being processed
    if (this.isRunning || this.isChannelPaused) return

    const queue = this.eventsQueue

    if (queue.length > 0) {
      this.isRunning = true

      while (queue.length > 0) {
        const event = queue.shift()

        const listeners = this.listenersList[event.eventName]

        if (!listeners) return
        // if no listeners subscribed to this event stop execution
        if (listeners.length === 0) return

        const listenersToBeRemoved: any[] = []

        for (const listener of listeners) {
          let { args } = event

          if (this.channelOptions && this.channelOptions.isSequence) {
            const control = {
              callback: listener,
              done: () => {
                if (control.isDone) {
                  throw new Error('done() is being called multiple times.')
                } else {
                  // lets not process every event on the same JS call stack
                  this.isRunning = false
                  setTimeout(() => this.proceed())
                }

                control.isDone = true
                this.doneQueue.splice(this.doneQueue.indexOf(control), 1)
              },
              isDone: false,
            }

            this.doneQueue.push(control)

            // control is always the last element in arguments
            if (event.args) {
              args = args.concat(control)
            } else {
              args = [control]
            }
          }
          listener.callback.apply(listener.context, args)

          if (listener.once) {
            listenersToBeRemoved.push({
              eventName: event.eventName,
              eventListener: listener,
            })
          }
        }

        while (listenersToBeRemoved.length > 0) {
          const current: any = listenersToBeRemoved.shift()
          this.removeEventListener(current.eventName, current.eventListener.callback, current.eventListener.context)
        }
      }
    } else {
      if (this.doneQueue.length === 0 && this.triggerExecOnEmptyQueue) {
        this.triggerExecOnEmptyQueue()
      }
      if (this.doneQueue.length === 0 && this.triggerExecOnEmptyQueueOnce) {
        this.triggerExecOnEmptyQueueOnce()
        delete this.triggerExecOnEmptyQueueOnce
      }
    }

    if (this.channelOptions && !this.channelOptions.isSequence) {
      this.isRunning = false
    }
  }

  flush() {
    this.eventsQueue = []
  }
}
